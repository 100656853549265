import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { Autocomplete, Avatar, Card, Grid, Icon, Skeleton, MenuItem, FormControl, InputLabel, Typography, Modal, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Transactions from "../Transactions";
import TransactionsSkeleton from "../TransactionsSkeleton";
import SiteFooter from "../Common/SiteFooter";
import MDAvatar from "../../../../../components/MDAvatar";
import URLSettings from "../Common/URLSettings";
import MagicParticles from "../Common/MagicParticles";
import { txtColorChange, txtColorChange1, bgColorChange } from "../Common/ColorChange";
import Logout from "../Common/Logout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
// import { Multiselect } from "multiselect-react-dropdown";
import InputEmoji from 'react-input-emoji'
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Filter from "bad-words";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Icon from "@mui/material/Icon";
import breakpoints from "assets/theme/base/breakpoints";

const jose = require("jose");

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Send() {
  let APIURL = URLSettings();
  const location = useLocation(); 

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  
  let eId;
  let employeeId;
  let fName;
  let lName;
  let deVal = [];
  if (location.search != "") {
    const empId = location.search.split("=");
    eId = empId[1].split("&");
    employeeId = empId[2].split("&");
    fName = empId[3].split("&");
    lName = empId[4].split("&");

    eId = eId[0];
    employeeId = employeeId[0];
    fName = fName[0];
    lName = lName[0];
    deVal = [{id: eId, first_name: fName, last_name: lName, employee_id: employeeId, profile_pic: null}]
  }
  

  const [defaultRec, setDefaultRec] = useState(deVal);
  
  const [desiredPoints, setDesiredPoints] = useState(0);
  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [pointsError, setPointsError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [Recipient, setRecipient] = useState([eId]); 
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [pointsMessage, setPointsMessage] = useState("");
  const [msg, setMessage] = useState("");
  const navigate = useNavigate();
  const [defaultTemplate, setDefaultTemplate] = useState({ id: "", name: "" });
  const profilPic = localStorage.getItem("profilepic");
  const [selectedValues, setSelectedVal] = useState();
  const [disablePoints, setDisablePoints] = useState(true);
  const [disableMsg, setDisableMsg] = useState(true);
  const [pointsCal, setPointsCal] = useState(0);

  const filter = new Filter();
  const [filteredInput, setFilteredInput] = useState("");

  // const handleChandeMsgInput = (e) => {
  //   const inputText = e.target.value;
  //   const filteredText = filter.clean(inputText)
  //   console.log("msg::", inputText);
  //   setMessage(inputText);
  // }
  const [submitVal, setSubmitVal] = useState();
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let pString = obj.find((o) => o.Name === "point_conversion");

  //Modal popup
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [msgRecipient, setMsgRecipient] = useState([eId]);
  const [userMsgSuggestions, setUserMsgSuggestions] = useState([]);

  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
    if (templateError) {
      setTemplateError(!templateError);
    }
    if (searchError) {
      setSearchError(!searchError);
    }
    if (pointsError) {
      setPointsError(!pointsError);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendError(false);
      setTemplateError(false);
      setSearchError(false);
      setPointsError(false);
      setSendSuccess(false);
    }, 5000);
  }, [sendError, templateError, searchError, pointsError, sendSuccess]);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const history = useQuery("senderHistory", async () =>
    axios.get(`${APIURL.url}/getSenderHistory`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const points = useQuery("senderPoints", async () =>
    axios.get(`${APIURL.url}/getPointCount`, {
      params: {
        isSender: true,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const templates = useQuery(
    "templates",
    async () =>
      axios.get(`${APIURL.url}/getMessageTemplate`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onSuccess: (data) => {
        const tempData = data.data.filter((list) => list.is_default === 1);
        // console.log("temp::", tempData);
        if (tempData.length > 0) {
          setDefaultTemplate(tempData[0]);
        }
      },
    }
  );

  const sendPoints = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/sendPoints`,
        {
          points: desiredPoints,
          message: pointsMessage,
          destinationAccountID: Recipient,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        handleClose();
        setDisablePoints(true);
        setEMsg(err.response.data);
        if (err.response.data === "Insufficient Points") {
          setPointsError(true);
        } else {
          setSendError(true);
        }
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: () => {
        setDisablePoints(true);
        setSendSuccess(true);
        handleClose();
        // reset fields
        setDesiredPoints(0);
        setRecipient([]);
        setPointsMessage("");

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        // fetch data - todo invalidation
        points.refetch();
        history.refetch();
      },
    }
  );

  const sendMessage = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/sendMessage`,
        {
          destinationAccountID: msgRecipient,
          message: msg,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setDisableMsg(true);
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: () => {
        setDisableMsg(true);
        setSendSuccess(true);
        // reset fields
        setDesiredPoints(0);
        setMsgRecipient([]);
        setMessage("");

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        // fetch data - todo invalidation
        points.refetch();
        history.refetch();
      },
    }
  );

  const getSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getUserSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        setUserSuggestions(data.data);
      },
    }
  );

  const getMsgSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getUserSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        setUserMsgSuggestions(data.data);
      },
    }
  );

  const getUsersList = useQuery("getUsersList", async () =>
    axios.get(`${APIURL.url}/getUserSuggestions`, {
      params: {
        isSender: true,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const msgPointsCheck = useMutation(
    async () =>
    axios.post(
      `${APIURL.url}/messageCheck`,
      {
        message: pointsMessage,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    ),
    {
      onError: (err) => {
        setDisableMsg(true);
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (response) => {
        if (response.data.clean === true) {
          setDisablePoints(false);
          // setPointsMessage(response.data.message);
          sendPoints.mutate();
        } else {
          setPointsMessage(response.data.message);
          handleClose();
          setEMsg('Your message contains  inappropriate language, which is replaced with *. Please rectify.');
          setSendError(true);
        }
      }
    },
  );

  const handleSendPoints = () => {
    if (desiredPoints > 0) {
      setDisablePoints(false);
      msgPointsCheck.mutate();
    } else {
      setEMsg('Negative values are not accepted');
      setSendError(true);
    }
    
  }

  // const handleSendPoints = () => {
  //   const filteredText = filter.clean(pointsMessage);
  //   if (filteredText.includes('*')) {
  //     setPointsMessage(filteredText);
  //     handleClose();
  //     setEMsg('Your message contains  inappropriate language, which is replaced with *. Please rectify.');
  //     setSendError(true);
  //   } else {
  //     setDisablePoints(false);
  //     sendPoints.mutate();
  //   }
  // };

  const updateTemplate = (e, option) => {
    if (option) {
      if (option.props.value.is_default === 1) {
        setMessage("");
      } else {
        setMessage(option.props.value.message);
      }
    } else {
      setMessage("");
    }
  };


  const msgCheck = useMutation(
    async () =>
    axios.post(
      `${APIURL.url}/messageCheck`,
      {
        message: msg,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    ),
    {
      onError: (err) => {
        setDisableMsg(true);
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (response) => {
        if (response.data.clean === true) {
          setDisableMsg(false);
          // setMessage(response.data.message);
          sendMessage.mutate();
        } else {
          setMessage(response.data.message)
          setEMsg('Your message contains  inappropriate language, which is replaced with *. Please rectify.');
          setSendError(true);
        }
      }
    },
  );

  const handleSendMessage = () => {
    msgCheck.mutate();
  }

  // console.log("msg check::", msgCheck);

  // const handleSendMessages = () => {
  //   const filteredText = filter.clean(msg);
  //   if (filteredText.includes('*')) {
  //     setMessage(filteredText)
  //     setEMsg('Your message contains  inappropriate language, which is replaced with *. Please rectify.');
  //     setSendError(true);
  //   } else {
  //     setDisableMsg(false);
  //     sendMessage.mutate();
  //   }
  // };

  const updatePointsTemplate = (e, option) => {
    if (option) {
      if (option.props.value.is_default === 1) {
        setPointsMessage("");
      } else {
        setPointsMessage(option.props.value.message);
      }
    } else {
      setPointsMessage("");
    }
  };

  const updateSuggestions = (e) => {
    getSuggestions.mutate(e.target.value);
  };

  const updateRecipient = (e, option) => {
    if (option) {
      setRecipient(option?.map(function(a){return a.id;}));
    }
  };

  const updateMsgSuggestions = (e) => {
    getMsgSuggestions.mutate(e.target.value);
  };

  const updateMsgRecipient = (e, option) => {
    if (option) {
      setMsgRecipient(option?.map(function(a){return a.id;}));
    } 
  };

  //Group Related Code
  const [groupSuggestions, setGroupSuggestions] = useState([]);
  const [groupRecipient, setgroupRecipient] = useState([eId]); 
  const getGroupSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getGroupSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        setGroupSuggestions(data.data.groups);
      },
    }
  );
  const updateGroupSuggestions = (e) => {
    getGroupSuggestions.mutate(e.target.value);
  };

  const updateGroupRecipient = (e, option) => {
    if (option) {
      setgroupRecipient(option?.map(function(a){return a.name;}));
    }
  };


  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  if (history.isError || points.isError || templates.isError || getUsersList.isError) {
    if (history.isError) {
      if (history.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
    if (points.isError) {
      if (points.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
    if (templates.isError) {
      if (templates.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
    if (getUsersList.isError) {
      if (getUsersList.error.response.status === 401) {
        window.location.reload();
        return navigate("/login"); 
      }
    }

    return (
      <Card id="sendPointsCard" sx={{ overflow: "visible" }}>
        <MDBox p={3} m={1} mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <MDAlert color="error" dismissible>
                Error: unable to load account data, please try again later.
              </MDAlert>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <MDAlert color="error" dismissible>
                    Error: unable to load account data.
                  </MDAlert>
                </Grid>
                <Grid item xs={12}>
                  <Grid Container>
                    <Grid item xs={2}>
                      <MDInput
                        type="number"
                        label="Points"
                        value={desiredPoints}
                        onChange={(e) => {
                          setDesiredPoints(e.target.value);
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MDInput type="search" label="Recipient" disabled />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        label="Message"
                        multiline
                        rows={4}
                        value={msg}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDAlert color="error" dismissible>
                        Error: unable to load Templates.
                      </MDAlert>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" color="primary" onClick={handleSendPoints} disabled sx={bgColorChange}>
                    Send Points <Icon>send</Icon>
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" color="info" onClick={handleSendMessage} disabled sx={bgColorChange}>
                    Send Message <Icon>mail</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    );
  }

  if (!(history.isSuccess && points.isSuccess && templates.isSuccess && token.isSuccess && getUsersList.isSuccess)) {
    return (
      <Card id="sendPointsCard" sx={{ overflow: "visible" }}>
        <MDBox p={3} m={1} mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <TransactionsSkeleton />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <MDTypography variant="h6" fontWeight="medium">
                  Send Message
                </MDTypography>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100%" height={20} animation="wave" />
                </Grid>
                <Grid item xs={12}>
                  <Grid Container>
                    <Grid item xs={2}>
                      <MDInput
                        type="number"
                        label="Points"
                        value={desiredPoints}
                        onChange={(e) => {
                          setDesiredPoints(e.target.value);
                        }}
                        disabled
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <MDInput type="search" label="Recipient" disabled sx={{ mb: 1 }} />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        defaultValue="Custom"
                        options={[]}
                        renderInput={(params) => (
                          <MDInput
                            label="Select Template Type"
                            {...params}
                            variant="standard"
                            sx={{ mb: 1 }}
                          />
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        label="Message"
                        multiline
                        rows={4}
                        value={msg}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        disabled
                        sx={{ mb: 1 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" color="primary" onClick={handleSendPoints} disabled sx={bgColorChange}>
                    Send Points <Icon sx={{ ml: 0.5 }}>send</Icon>
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="gradient" color="info" onClick={handleSendMessage} disabled sx={bgColorChange}>
                    Send Message <Icon sx={{ ml: 0.5 }}>mail</Icon>
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    );
  }


  function handleOnEnter (text) {
    console.log('enter', text)
  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDBox my={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} mb={2} sx={{ paddingTop: "0px !important"}}>
            <Card id="sendPointsCard" sx={{ overflow: "visible" }}>
              <MDSnackbar
                color="error"
                icon="error"
                title="Error: Unable to Send"
                content={eMsg}
                open={sendError}
                close={toggleSnackbar}
              />
              <MDSnackbar
                color="success"
                icon="check_circle"
                title="Success"
                content="Message Sent"
                open={sendSuccess}
                close={toggleSnackbar}
              />
              <MDSnackbar
                color="error"
                icon="error"
                title="Error: Unable to Load Template"
                content={eMsg}
                open={templateError}
                close={toggleSnackbar}
              />
              <MDSnackbar
                color="error"
                icon="error"
                title="Error: Unable to Search Users"
                content={eMsg}
                open={searchError}
                close={toggleSnackbar}
              />
              <MDSnackbar
                color="error"
                icon="error"
                title="Error: Insufficient Points"
                content={eMsg}
                open={pointsError}
                close={toggleSnackbar}
              />
              <div className="blockCanvas">
              <Transactions history={history.data.data} isGiftingAccount />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6} mb={2} sx={{ paddingTop: "0px !important"}}>
            <Card> 
              {/* <AppBar position="static">
                <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Individual"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        person
                      </Icon>
                    }
                  />
                  <Tab
                    label="Group"
                    icon={ 
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        people
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>

              <CustomTabPanel value={tabValue} index={0}> */}
                {sendSuccess && 
                  <MagicParticles />
                }
                <MDBox p={2}>
                  <Grid container>
                    {token.data.has_giving_account && (
                      <Grid item xs={12}>
                        <MDTypography
                          variant="h6"
                          mb={3}
                          sx={txtColorChange}
                          className="fontFamily"
                        >
                          <MDBox sx={txtColorChange} className="floatleft">You have </MDBox> 
                          <MDBox sx={txtColorChange1} className="floatleft">{points.data.data[0]?.giving_balance}</MDBox>
                          <MDBox sx={txtColorChange} className="floatleft"> points to give</MDBox>
                        </MDTypography>
                      </Grid>
                    )}
                    {token.data.has_giving_account && (
                      <Grid item xs={12}>
                        <Grid
                          Container
                          sx={{
                            border: "1px solid #ccc",
                            p: 2,
                            borderRadius: "15px",
                            mb: 3,
                            boxShadow: "0px 3px 3px #ccc",
                          }}
                        >
                          <Grid item xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} className="fontFamily">
                              Send Points
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9} sx={{ mb: 3 }}>
                            <Autocomplete
                              multiple
                              options={userSuggestions}
                              renderOption={(props, option) => (
                                <li {...props} key={option.userid}>
                                  {option.profile_pic ? (
                                    <MDAvatar
                                      src={option.profile_pic}
                                      alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                        0,
                                        1
                                      )}`}
                                      size="sm"
                                      bgColor="light"
                                    />
                                  ) : (
                                    <Avatar
                                      size="sm"
                                      sx={{
                                        bgcolor: "#ccc",
                                        marginRight: "5px",
                                        textTransform: "uppercase",
                                      }}
                                    >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                      0,
                                      1
                                    )}`}</Avatar>
                                  )}
                                  {`${option.first_name} ${option.last_name}`} <br/>
                                  {`${option.employee_id}`}
                                </li>
                              )}
                              getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name}`
                              }
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onChange={updateRecipient}
                              renderInput={(params) => (
                                <MDInput
                                  label="Select Recipient"
                                  {...params}
                                  variant="standard"
                                  onChange={updateSuggestions}
                                  sx={{ mb: 3 }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            <MDInput
                              type="input"
                              label="Points"
                              value={desiredPoints}
                              onChange={(e) => {
                                setPointsCal(e.target.value/pString.Value)
                                setDesiredPoints(e.target.value);
                              }}
                              sx={{ mb: 3, width: "100%" }}
                            />
                            <span className="pointsCal">{desiredPoints}pts ≅ ${pointsCal}</span>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                              <InputLabel id="demo-simple-select-standard-label">Select Template Type</InputLabel>
                              <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Template Type" defaultValue={defaultTemplate} onChange={updatePointsTemplate} sx={{minHeight: "40px"}}>
                                {templates.data.data.map((template) => (
                                  <MenuItem value={template}>{template.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9} sx={{mb:3}}>
                            <InputEmoji
                                value={pointsMessage}
                                onChange={setPointsMessage}
                                cleanOnEnter
                                onEnter={handleOnEnter}
                                placeholder="Message"
                                borderRadius={8}
                                className="emojiPicker"
                              />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <MDButton
                              variant="gradient"
                              color="primary"
                              className="ml-5"
                              onClick={handleOpen}
                              sx={bgColorChange}
                              disabled = {disablePoints ? false : true }
                            >
                              Send Points <Icon sx={{ ml: 0.5 }}>send</Icon>
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid
                        Container
                        sx={{
                          border: "1px solid #ccc",
                          p: 2,
                          borderRadius: "15px",
                          mb: 3,
                          boxShadow: "0px 3px 3px #ccc",
                        }}
                      >
                        <Grid item xs={12}>
                          <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} className="fontFamily">
                            Send Message
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9} sx={{ mb: 3 }}>
                          <Autocomplete
                            multiple
                            options={userMsgSuggestions}
                            renderOption={(props, option) => (
                              <li {...props} key={option.userid}>
                                {option.profile_pic ? (
                                  <MDAvatar
                                    src={option.profile_pic}
                                    alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                      0,
                                      1
                                    )}`}
                                    size="sm"
                                    bgColor="light"
                                  />
                                ) : (
                                  <Avatar
                                    size="sm"
                                    sx={{
                                      bgcolor: "#ccc",
                                      marginRight: "5px",
                                      textTransform: "uppercase",
                                    }}
                                  >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                    0,
                                    1
                                  )}`}</Avatar>
                                )}
                                {`${option.first_name} ${option.last_name}`} <br />
                                {`${option.employee_id}`}
                              </li>
                            )}
                            getOptionLabel={(option) =>
                              `${option.first_name} ${option.last_name}`
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={defaultRec}
                            onChange={updateMsgRecipient}
                            renderInput={(params) => (
                              <MDInput
                                label="Select Recipient"
                                {...params}
                                variant="standard"
                                onChange={updateMsgSuggestions}
                                sx={{ mb: 3 }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-standard-label">Select Template Type</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Template Type" defaultValue={defaultTemplate} onChange={updateTemplate} sx={{minHeight: "40px"}}>
                              {templates.data.data.map((template) => (
                                <MenuItem value={template}>{template.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9} sx={{mb:3}}>
                          <InputEmoji
                            value={msg}
                            onChange={setMessage}
                            cleanOnEnter
                            onEnter={handleOnEnter}
                            placeholder="Message"
                            borderRadius={8}
                            className="emojiPicker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton variant="gradient" color="info" onClick={handleSendMessage} sx={bgColorChange} disabled = {disableMsg ? false : true }>
                            Send Message <Icon sx={{ ml: 0.5 }}>mail</Icon>
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              {/* </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {sendSuccess && 
                  <MagicParticles />
                }
                <MDBox p={2}>
                  <Grid container>
                    {token.data.has_giving_account && (
                      <Grid item xs={12}>
                        <MDTypography
                          variant="h6"
                          mb={3}
                          sx={txtColorChange}
                          className="fontFamily"
                        >
                          <MDBox sx={txtColorChange} className="floatleft">You have </MDBox> 
                          <MDBox sx={txtColorChange1} className="floatleft">{points.data.data[0]?.giving_balance}</MDBox>
                          <MDBox sx={txtColorChange} className="floatleft"> points to give</MDBox>
                        </MDTypography>
                      </Grid>
                    )}
                    {token.data.has_giving_account && (
                      <Grid item xs={12}>
                        <Grid
                          Container
                          sx={{
                            border: "1px solid #ccc",
                            p: 2,
                            borderRadius: "15px",
                            mb: 3,
                            boxShadow: "0px 3px 3px #ccc",
                          }}
                        >
                          <Grid item xs={12}>
                            <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} className="fontFamily">
                              Send Points
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9} sx={{ mb: 3 }}>
                            <Autocomplete
                              multiple
                              options={groupSuggestions}
                              renderOption={(props, option) => (
                                <li {...props} key={option.name}>
                                  {`${option.name}`}
                                </li>
                              )}
                              getOptionLabel={(option) =>
                                `${option.name}`
                              }
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              onChange={updateGroupRecipient}
                              renderInput={(params) => (
                                <MDInput
                                  label="Select Group"
                                  {...params}
                                  variant="standard"
                                  onChange={updateGroupSuggestions}
                                  sx={{ mb: 3 }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            <MDInput
                              type="input"
                              label="Points"
                              value={desiredPoints}
                              onChange={(e) => {
                                setPointsCal(e.target.value/100)
                                setDesiredPoints(e.target.value);
                              }}
                              sx={{ mb: 3, width: "100%" }}
                            />
                            <span className="pointsCal">{desiredPoints}pts ≅ ${pointsCal}</span>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9}>
                            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                              <InputLabel id="demo-simple-select-standard-label">Select Template Type</InputLabel>
                              <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Template Type" defaultValue={defaultTemplate} onChange={updatePointsTemplate} sx={{minHeight: "40px"}}>
                                {templates.data.data.map((template) => (
                                  <MenuItem value={template}>{template.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={9} lg={9} sx={{mb:3}}>
                            <InputEmoji
                                value={pointsMessage}
                                onChange={setPointsMessage}
                                cleanOnEnter
                                onEnter={handleOnEnter}
                                placeholder="Message"
                                borderRadius={8}
                                className="emojiPicker"
                              />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <MDButton
                              variant="gradient"
                              color="primary"
                              className="ml-5"
                              onClick={handleOpen}
                              sx={bgColorChange}
                              disabled = {disablePoints ? false : true }
                            >
                              Send Points <Icon sx={{ ml: 0.5 }}>send</Icon>
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid
                        Container
                        sx={{
                          border: "1px solid #ccc",
                          p: 2,
                          borderRadius: "15px",
                          mb: 3,
                          boxShadow: "0px 3px 3px #ccc",
                        }}
                      >
                        <Grid item xs={12}>
                          <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} className="fontFamily">
                            Send Message
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9} sx={{ mb: 3 }}>
                          <Autocomplete
                            multiple
                            options={userMsgSuggestions}
                            renderOption={(props, option) => (
                              <li {...props} key={option.userid}>
                                {option.profile_pic ? (
                                  <MDAvatar
                                    src={option.profile_pic}
                                    alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                      0,
                                      1
                                    )}`}
                                    size="sm"
                                    bgColor="light"
                                  />
                                ) : (
                                  <Avatar
                                    size="sm"
                                    sx={{
                                      bgcolor: "#ccc",
                                      marginRight: "5px",
                                      textTransform: "uppercase",
                                    }}
                                  >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                                    0,
                                    1
                                  )}`}</Avatar>
                                )}
                                {`${option.first_name} ${option.last_name}`} <br />
                                {`${option.employee_id}`}
                              </li>
                            )}
                            getOptionLabel={(option) =>
                              `${option.first_name} ${option.last_name}`
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={defaultRec}
                            onChange={updateMsgRecipient}
                            renderInput={(params) => (
                              <MDInput
                                label="Select Recipient"
                                {...params}
                                variant="standard"
                                onChange={updateMsgSuggestions}
                                sx={{ mb: 3 }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={9} lg={9}>
                          <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                            <InputLabel id="demo-simple-select-standard-label">Select Template Type</InputLabel>
                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Template Type" defaultValue={defaultTemplate} onChange={updateTemplate} sx={{minHeight: "40px"}}>
                              {templates.data.data.map((template) => (
                                <MenuItem value={template}>{template.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={9} lg={9} sx={{mb:3}}>
                          <InputEmoji
                            value={msg}
                            onChange={setMessage}
                            cleanOnEnter
                            onEnter={handleOnEnter}
                            placeholder="Message"
                            borderRadius={8}
                            className="emojiPicker"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDButton variant="gradient" color="info" onClick={handleSendMessage} sx={bgColorChange} disabled = {disableMsg ? false : true }>
                            Send Message <Icon sx={{ ml: 0.5 }}>mail</Icon>
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </CustomTabPanel> */}              
            </Card>
          </Grid>
        </Grid>
        
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalPopupBox">
            <Grid container> 
              <Typography sx={{ fontSize: "14px" }} color="secondary">
                Are you sure you want to confirm?
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mt={2} mb={1}>
                  <MDButton variant="gradient" color="secondary" onClick={handleClose} className="spacing">
                    Cancel
                  </MDButton>
                  <MDButton variant="gradient" color="success" onClick={handleSendPoints} sx={bgColorChange} disabled = {disablePoints ? false : true }>
                    Confirm
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Box>
        </Modal>

      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default Send;
