import { Box, Grid, Modal, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import NotificationItem from "examples/Items/NotificationItem";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import CartCount from "./CartCount";
import Loader from "./Loader";
// import Loader from "./Loader";
import URLSettings from "./URLSettings";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const jose = require("jose");

function Cart({ updateCountFromCart }) {
  let APIURL = URLSettings();

  const [cartDel, setCartDel] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [eMsg, setEMsg] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let pString = obj.find((o) => o.Name === "point_conversion");
  const toggleSnackbar = () => {
    if (error) {
      setError(!error);
    }
    if (success) {
      setSuccess(!success);
    }
    if (eMsg) {
      setEMsg(!eMsg);
    }
    if (cartDel) {
      setCartDel(!cartDel);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
      setCartDel(false);
    }, 2000);
  }, [success, error]);

  //Cart display
  var cartItemsList = JSON.parse(localStorage.getItem("cartItems"));

  const handleDelete = (cartObj) => {
    let cartList = JSON.parse(localStorage.getItem("cartItems"));

    if (cartObj > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      cartList.splice(cartObj, 1);
    }
    localStorage.setItem("cartItems", JSON.stringify(cartList));
    let newCartList = JSON.parse(localStorage.getItem("cartItems"));
    //setUpdateCount(newCartList.length);
    updateCountFromCart(newCartList.length);
    setCartDel(true);
  };

  const claims = jose.decodeJwt(localStorage.getItem("token"));

  // const proceedCartOrder = useMutation(
  //   () =>
  //     axios.post(
  //       `${APIURL.url}/redeem/giftCard`,
  //       {
  //         cart: JSON.parse(localStorage.getItem("cartItems")),
  //         email: email,
  //         c_email: confirmEmail,
  //         note: "Gift card redemtion",
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     ),
  //   {
  //     onError: (err) => {
  //       setEMsg(err.response.data);
  //       console.log(err);
  //       setError(true);
  //       if (err.response.status === 401) {
  //         logout();
  //       }
  //     },
  //     onSuccess: (data) => {
  //       // modal
  //       handleClose();
  //       setConfirmEmail("");
  //       setSuccess(true);
  //       // setCode(data);
  //       localStorage.removeItem("cartItems");
  //     },
  //   }
  // );

  // const placeOrder = () => {
  //   proceedCartOrder.mutate();
  // };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  let modifyCart = cartItemsList?.filter(
    (ele, ind) =>
      ind ===
      cartItemsList.findIndex((elem) => elem.code === ele.code && elem.points === ele.points)
  );

  let cartLength;
  if (modifyCart === undefined) {
    cartLength = 0;
  } else {
    cartLength = modifyCart?.length;
  }

  // if (proceedCartOrder.isLoading) {
  //   return <Loader />;
  // }

  return (
    <>
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to place order"
        content={eMsg}
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Order placed successful"
        open={success}
        close={toggleSnackbar}
      />
      <MDSnackbar
        autoHideDuration="1000"
        color="error"
        icon="error"
        title="Delete Cart"
        content="Gift Card deleted from cart"
        open={cartDel}
        close={toggleSnackbar}
      />
      {cartLength === 0 && (
        <MDBox py={0.5} display="flex" alignItems="center" lineHeight={1}>
          <MDTypography variant="button" fontWeight="regular" sx={{ ml: 2 }}>
            No items in Cart
          </MDTypography>
        </MDBox>
      )}
      <MDTypography sx={{ fontSize: "12px", textAlign: "right"}}>($1 = {pString.Value}pts)</MDTypography>
      {modifyCart?.map((item, key) => (
        <NotificationItem
          icon={<img src={item.gift_card_image} width="75" />}
          title={`$${item.points}`}
          quantity={CartCount(item.code, item.points)}
          keyNum={key}
          handleCartDelete={handleDelete}
          // deleteEntry={true}
        />
      ))}
      {cartLength !== 0 && (
        <Link to="/ordercart">
          <MDButton
            color="success"
            size="small"
            fullWidth
            sx={{ marginTop: "10px" }}
            // onClick={handleShow}
          >
            {/* Proceed to Order */}
            Go to Cart
          </MDButton>
        </Link>
      )}

      {/* <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2">
                Confirmation
              </Typography>
            </Grid>
          </Grid>
          <MDBox component="form" role="form">
            <MDBox mt={2} mb={2}>
              <MDInput
                type="text"
                fullWidth
                label="Email Id"
                placeholder="Email Id"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDInput
                type="email"
                name="confirmName"
                fullWidth
                label="Confirm Email Id"
                placeholder="Confirm Email Id"
                value={confirmEmail}
                onChange={(e) => {
                  setConfirmEmail(e.target.value);
                }}
              />
            </MDBox>
            <Typography sx={{ fontSize: "12px" }}>
              Note: Please copy paste your email if need to be updated, please contact your
              supervisor
            </Typography>
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" onClick={placeOrder}>
                Confirm
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal> */}
    </>
  );
}
export default Cart;
